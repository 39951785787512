/* ErrorPage.css */

.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4; /* Light gray for subtle background contrast */
    color: #0d0d3e; /* Matches the dark blue theme of your website */
    font-family: 'Arial', sans-serif;
    text-align: center;
}

.error-page h1 {
    font-size: 5rem;
    margin-bottom: 1rem;
    color: #ff4d4f; /* Bright red for the main "Oops!" message */
}

.error-page h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.error-page p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.home-link {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
    color: #ffffff;
    background-color: #0d0d3e; /* Dark blue to match your site's theme */
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.home-link:hover {
    background-color: #24244d; /* Slightly lighter shade on hover */
}
