/* LoadingSpinner.style.css */
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent overlay */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensures overlay is on top */
    /*pointer-events: auto; !* Captures all events *!*/
}

.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 10000; /* Keeps spinner above the overlay */
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #3498db;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: bounce 2s infinite ease-in-out;
}

.double-bounce2 {
    animation-delay: -1s;
}

@keyframes bounce {
    0%, 100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}

.spinner-overlay p {
    margin-top: 10px;
    font-size: 1.2em;
    color: #333;
    z-index: 10000; /* Keeps text above the overlay */
}
