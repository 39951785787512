/* MaintenancePage.css */

.maintenance-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4; /* Light gray background */
    color: #0d0d3e; /* Dark blue text */
    font-family: 'Arial', sans-serif;
    text-align: center;
}

.maintenance-page h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #ffa500; /* Bright orange to highlight maintenance */
}

.maintenance-page p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    max-width: 500px;
    line-height: 1.6;
}

.home-link {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
    color: #ffffff;
    background-color: #0d0d3e; /* Dark blue button to match site theme */
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.home-link:hover {
    background-color: #24244d; /* Slightly lighter blue for hover */
}
