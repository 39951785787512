* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow-x: hidden;
}

.app {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  text-align: center;
  background-color: #1a1a2e;
  padding: 20px 10px;
  color: #f2f2f2;
}

.header h1 {
  font-size: 2.5em;
  color: #ffcc00;
  cursor: pointer;
}

.header p {
  font-size: 1.2em;
  color: #b8c1ec;
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 30px 10px;
  flex-grow: 1;
}

.game-card {
  background-color: #1a1a2e;
  color: #f2f2f2;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 200px;
  max-height: 350px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.game-card:hover {
  transform: scale(1.05);
  background-color: #ffcc00;
  color: #1a1a2e;
}

.game-card h2 {
  font-size: 1.2em;
  margin: 10px 0;
}

.game-card p {
  font-size: 0.9em;
  color: #e0e1dd;
}
.game-card:hover p {
  color: #950652;
}

.game-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 6px;
  margin-bottom: 10px;
}

.footer {
  text-align: center;
  padding: 15px;
  background-color: #1a1a2e;
  color: #b8c1ec;
}

.footer-links a {
  color: #ffcc00;
  text-decoration: none;
  margin: 0 10px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer p {
  margin-top: 10px;
  font-size: 0.85em;
  color: #b8c1ec;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .header h1 {
    font-size: 2em;
  }

  .game-card {
    width: 100%;
    max-width: 300px;
  }

  .main-content {
    padding: 20px;
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 1.8em;
  }

  .game-card {
    width: 100%;
    max-width: 250px;
  }

  .main-content {
    padding: 15px;
    gap: 10px;
  }
}


/*//##############*/
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  font-size: 18px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.game-canvas {
  position: relative;
  width: 85vw;
  max-width: 768px;
  height: 85vh;
  max-height: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Receipt container at the top, centered */
.receipt-container {
  position: absolute;
  bottom: 20%;
  left:0;
  width: 60%;          /* Adapt width for smaller screens */
  max-width: 120px;    /* Limit the max width for larger screens */
  padding: 15px;
  border-radius: 20px;
  border: 1px solid rgba(51, 51, 51, 0.48);
  background-color: rgba(247, 247, 247, 0.82);
  color: rgba(51, 51, 51, 0.4);
  z-index: 2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  font-size: 14px;
  margin-bottom: 20px; /* Space from the game canvas */
  text-align: center;
  pointer-events: none;
}

/* Center receipt heading */
.receipt-container h3 {
  margin: 0 0 10px;
  font-size: 18px;
  text-align: center;
}

/* Adjust receipt item styling */
.receipt-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 14px;
}

/* Total amount styling */
.receipt-total {
  border-top: 1px solid #333;
  padding-top: 10px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.prevent-mouse-interaction {
  pointer-events: none;
}
