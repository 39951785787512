/* General styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: #000;
    color: #fff;
    font-family: 'Arial', sans-serif;
}

.game-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 500px;  /* Keep a consistent width */
    aspect-ratio: 16 / 9;
    /*background: #1a1a1a;*/
    border-radius: 10px;
    padding: 20px;
    position: relative;
    margin: auto;
    height: 560px;
    overflow: hidden;
}

/* Header */
.game-header {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 5px;
    font-size: 1.5rem;
}

.timeTxt {
    font-weight: bold;
    padding-top: 10px;
    font-size: 1.5rem;
}

.payment-label {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 10px;
    background-color: lightblue;
    padding: 10px 5px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}
.found-label {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    text-align: center;
    white-space: nowrap;
    font-weight: bold;
    font-size: 1.5rem;
}
.end-found {
    font-weight: bold;
    font-size: 2.6rem;
    padding-bottom: 10px;
}
/* Centered Title */
.title {
    flex-grow: 1;
    text-align: center;
    font-size: 1.2rem;
}

.emoji-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Default to 4 columns */
    gap: 10px;
    position: relative;
}

@media (max-width: 768px) {
    .emoji-grid {
        transform: scale(0.8); /* Scale down to 80% of original size */
    }
}

/* Hint Section */
.hint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    margin-top: 20px;
}

.hint span {
    font-weight: bold;
    font-size: 1.2rem;
}

.hint .hint-text {
    font-size: 1rem;
}

/* Text container */
.text-container {
    margin-bottom: 20px;
}

.found-text {
    font-size: 3rem;
    margin: 0;
}

.sub-text {
    font-size: 1.2rem;
    margin: 0;
}

/* Retry button */
.retry-button {
    width: 100px;
    height: 100px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: #000;
    cursor: pointer;
    margin-bottom: 20px;
}

.retry-icon {
    transform: rotate(0);
    display: block;
}

.end {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}
